@use "src/_theme.scss";
@use "src/_utils.scss";
@mixin Header ($HeaderHeight) {
// ---------------------------------------------------------------------------------------
// HEADER --------------------------------------------------------------------------
header {

  $h: $HeaderHeight;
  $logoHeight: $h/2;
  $spaceAboveAndBelowLogo: $h - $logoHeight;
  z-index: 10;
  position: fixed;
  top:0;
  left: 0;
  right:0;
  bottom: calc(100vh - #{$h});
  img {
    position: absolute;
    left: $spaceAboveAndBelowLogo/2;
    height: $h/2;
    top: $spaceAboveAndBelowLogo/2;
    //filter: drop-shadow(0 0 4px invert($bg, $weight: 100%));
    cursor: pointer;
  }

  .App-name {
    @include theme.titleFont;
    font-weight: 900;
    position: absolute;
    left: $logoHeight + $spaceAboveAndBelowLogo;
    top: 0;
    font-size: $logoHeight;
    line-height: $h;
    @include theme.titleFontColor;
    letter-spacing: 0;
    transform: scaleX(1) translateX(0) translateY(0);
    //transform: scale(0) translateX(-200px);
    transition: 0.2s transform ease-in-out;

  }

  img:hover + .App-name {
    transform: scaleX(1) translateX(0);
  }

}

@media only screen and (orientation: portrait) {
  header {
    max-height: 128px;
  }
}


.metamask-btn, .toggle-stats {

  $fS: 16px;
  $pV: $fS/4;
  $pH: $fS/2;
  $bW: $fS/8;

  $calcH: $pV*2 + $fS + $bW*2;



  position: fixed;
  top: ($HeaderHeight - $calcH)/2;
  right: ($HeaderHeight - $calcH)/2;

  @include utils.buttonSizing($fS);
  @include utils.buttonStyling;
  @include utils.buttonShadow;




  
  transform: scale(1);
  transition: 0.2s transform ease-in-out,
              0.2s background ease-in-out, 
              0.2s border ease-in-out, 
              0.2s color ease-in-out;
 
  

 

}
.metamask-btn {
  z-index: 12;
  $fS: 16px;
  $pV: $fS/4;
  $pH: $fS/2;
  $bW: $fS/8;

  $calcH: $pV*2 + $fS + $bW*2;
  right: ($HeaderHeight - $calcH)/2 + $fS*5;
}

.stats {
  position: fixed;
  z-index: 10;
  $fS: 16px;
  $pV: $fS/4;
  $pH: $fS/2;
  $bW: $fS/8;

  $calcH: $pV*2 + $fS + $bW*2;

  right: ($HeaderHeight - $calcH)/2;

  padding-right: 16px;
  box-sizing: border-box;
  top: $HeaderHeight + 16px;
  color: theme.$fontColor;
  font-size: $fS;
  top: ($HeaderHeight - $calcH)/2 + $calcH;

  $w: 400px;
  $poolFontSize: 16px;
  $gutter: $poolFontSize;

  font-size: $poolFontSize;


  margin: $gutter 0;

  box-sizing: border-box;
  border: 1px solid rgba(theme.$borderColor, 0.5);
  border-radius: $gutter;
  width: $w;
  padding: $gutter;

  color: theme.$fontColor;
  background: theme.$itemBG;

  filter: drop-shadow(2px 2px 2px black);
}



} // end of mixin