@mixin bg {
	.bg {
		filter: brightness(100%);
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		overflow: hidden;
		z-index: -1;
		img {

			object-fit: cover;
			width: 100%;
			height: 100%;

		}
	}

}