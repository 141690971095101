@use "src/_theme.scss";
@use "src/_utils.scss";
@mixin Pool ($PoolWidth) {
.pool {
  $w: $PoolWidth;
  $poolFontSize: 24px;
  $gutter: $poolFontSize;
  position: relative;
  font-size: $poolFontSize;

  display: inline-block;
  margin: $gutter/2;

  box-sizing: border-box;
  
  border-radius: $gutter;
  width: $w;
  color: theme.$fontColor;
  
  $h: 128px;
  height: $h;
  &.poolsvg--false {
    border: 1px solid rgba(theme.$borderColor, 0.5);
    background: theme.$itemBG;
    &.pool--closed {
      background: rgba(red, 0.6);
      &::after {
        content: "REWARDS ENDED";
        display: inline-block;

      }
      .pool__controls {
        .pool__btn + .pool__btn {
          display: none;
        }
      }

    }

    &.pool--open {
      &.poolrate--100 {
        filter: drop-shadow(0 0 8px adjust-hue(adjust-color(theme.$theme, $saturation: 100%), 180deg));
      }
      &.poolrate--50 {
        filter: drop-shadow(0 0 4px adjust-hue(adjust-color(theme.$theme, $saturation: 50%), 135deg));
      }
    } 
  }
  
  &__name {
    height: $poolFontSize*3;
    span, span a {
      height: $poolFontSize*3;
      display: block;
    }
    img {
      height: $poolFontSize*3;
      position: relative;
    
      filter: drop-shadow(2px 2px 2px black);
    }
    img.moonwasp {
      filter: drop-shadow(0 0 8px theme.$specialFontColor);
    }
  }

  .arrows {
    margin-right: $poolFontSize/2;
    margin-left: $poolFontSize/2;
    color: theme.$effectColor;
  }

  &__id {
    display: inline-block;
    font-size: $poolFontSize*1.5;
     @include theme.titleFont;
    @include theme.poolTitleFontColor;
    font-weight: 400;

    width: 10%;
  }
  &__main {
    width: 40%;
    border-left: 1px solid rgba(theme.$borderColor, 0.5);
    box-sizing: border-box;
    height: $h;
  }

  &__rewards {
    margin-top: $poolFontSize/10;
    border-top: 1px dashed theme.$borderColor;
    border-bottom: 1px dashed theme.$borderColor;
    padding-top: $poolFontSize/10;
    padding-bottom: $poolFontSize/10;
    margin-bottom: $poolFontSize/10;
  }

  &__rewards,
  &__depositFee  {
    display: inline-block;
  }
  &__section {
    display: inline-block;
    vertical-align: middle;

  }
&__row {
    display: block;
    height: $h/2;
    vertical-align: middle;
    line-height: $h/2;
  }

&__balance {
  font-size: $poolFontSize/2;
}
  button.pool__btn {
    

    
    cursor: pointer;
    transform: scale(1);
    width: 50%;
    display: inline-block;
    box-sizing: border-box;
    background: none;
    border-top: 1px solid rgba(theme.$borderColor, 0.4);
    border-left: none;
     border-right: none;
     border-bottom: none;

    color: theme.$fontColor;
    font-size: $poolFontSize/2;
    position: relative;
    top:-$gutter/4;
    height: $h/2;
    margin: 0;
    padding: 0;
      transition: 0.2s letter-spacing ease-in-out,  0.2s background ease-in-out;
    & + button.pool__btn {
      border-left: 1px solid rgba(theme.$borderColor, 0.4);
    }

    &:hover {
      letter-spacing: 2px;
      background: theme.$theme3;
    }
    text-decoration: underline;
  
  }

  button.pool__harvest {
    background: none;
    font-size: $poolFontSize;
    border: none;
    color: theme.$fontColor;
    cursor: pointer;
    height: 128px;
    letter-spacing: 0px;
    transition: 0.2s letter-spacing ease-in-out,  0.2s background ease-in-out;
    width: 100%;
    border-top-right-radius: $gutter;
    border-bottom-right-radius: $gutter;
    text-decoration: underline;
    &:hover {
   
      background: theme.$theme3;
    }
  }

  &__controls {
    width: 50%;
    border-left: 1px solid rgba(theme.$borderColor, 0.5);
    box-sizing: border-box;
    height: $h;
  }

  &__rate {
    position: absolute;
    top: -$gutter/2;
    right: $gutter;
    font-size: $poolFontSize*2/3;
    padding: $poolFontSize/8 $poolFontSize/4;
    background: theme.$theme2;
    border-radius: $poolFontSize;
    filter: drop-shadow(2px 2px 4px rgba(0,0,0,0.4));
  }

  &__locked {
    position: absolute;
    top: $gutter*2 + 8px;
    right: $gutter;
    font-size: $poolFontSize*2/3;
    padding: $poolFontSize/8 $poolFontSize/4;
    background: none;
  }



}

}

