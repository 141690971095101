
@use "src/_theme.scss"; 
@use "src/_header.scss";
@use "src/_utils.scss";
@use "src/_pool.scss";
@use "src/_dapp.scss";
@use "src/_bg.scss";

$theme: theme.$theme;
$theme1: theme.$theme1; 
$theme2: theme.$theme2;
$theme3: theme.$theme3;


$bg: theme.$bg;
$itemBG: theme.$itemBG;

$fontColor: theme.$fontColor;
$specialFontColor: theme.$specialFontColor;

$borderColor: theme.$borderColor;

$effectColor: theme.$effectColor;

$HeaderHeight: 128px;
$PoolWidth: 1080px;

body, button, input, p {
  @include theme.mainFont;
}

body {
  background: black;
  font-weight: 300;
  margin: 0;
}
button {
  font-weight: 600;
}
.App {
  min-height: 100vh;
  margin: 0;
}
.App.darkModeOn--true {
  background: $bg;

}



@include header.Header($HeaderHeight);

.dark-mode-toggle {
  $fS: 16px;
  $pV: $fS/4;
  $pH: $fS/2;
  $bW: $fS/8;

  $calcH: $pV*2 + $fS + $bW*2;

  cursor: pointer;

  position: fixed;
  top: ($HeaderHeight - $calcH)/2;
  right: ($HeaderHeight - $calcH)/2 + 128px;

  @include utils.buttonSizing(16px);

  outline: none;
  background: none;
  color: $borderColor;
  
  transform: scale(1);
  transition: 0.2s transform ease-in-out,
              0.2s background ease-in-out, 
              0.2s color ease-in-out;

  &:hover {
    background: $bg;
    color: $effectColor;
    transform: scale(1.1);
  }
}

// ---------------------------------------------------------------------------------------
// Pools --------------------------------------------------------------------------
.App-core {
  width: auto;
  box-sizing: border-box;
  padding: $HeaderHeight 64px 64px;
  position: relative;
  z-index: 1;
  text-align: center;
}

.App-converter {
  max-width: 640px;
  background: linear-gradient(45deg, #6e194b, #8a4f1f, #1e7019);
  border: 1px solid rgba(255,255,255,0.4);
  border-radius: 8px;
  filter: drop-shadow(0 2px 2px rgba(0,0,0,0.4));
  padding: 16px;
  margin: 8px auto;
  &__title {
    font-size: 24px;
    color: white;
    margin-bottom: 32px;
  }
  p {
    color: #aeaeae;
  }
}

.App-userBalances {
  div {
    height: 64px;
    display: inline-block;
    line-height: 64px;
    margin: 16px;

    .token-icon {
      height: 64px;
      vertical-align: middle;
    }
    span {
      font-size: 24px;
      color: white;
      vertical-align: middle;
      margin-left: 8px;
    }
  }
}
.App-Folio {
  div.Portfolio-Title {
    display:  block;
    color: white;
    font-size: 18px;
    line-height: 24px;
    height: 24px;
    margin: 8px;
  }
  div {
    height: 48px;
    display: inline-block;
    line-height: 48px;
    margin: 16px;

    .token-icon {
      height: 48px;
      vertical-align: middle;
    }
    span {
      font-size: 18px;
      color: white;
      vertical-align: middle;
      margin-left: 8px;
    }
  }
}

@include pool.Pool($PoolWidth);


// ---------------------------------------------------------------------------------------
// Input Overlay --------------------------------------------------------------------------

$inputOverlayButtonSize: 20px;
$inputOverlayButtonHeight: 7*$inputOverlayButtonSize/4;
$inputOverlayGutter: 16px;
.btn {
  @include utils.buttonSizing($inputOverlayButtonSize);
  @include utils.buttonStyling;
  & + .btn {
    margin-left: 8px;
  }
}


.theInput {
  &.TOGGLE__theInput--false {
    display: none;
  }
  &.TOGGLE__theInput--true {
    display: block;
  }

  $w: $PoolWidth;

  z-index: 12;
  position: fixed;
  text-align: center;
  background: rgba(0,0,0,0.8);
  top: 0;
  left: 0;
  right: 0; 
  bottom: 0;

  $inputH: $w/6.5;
  $like8px: $w/39;
  input {
    display: block;
     outline: none;
    border: min($like8px/4,2px) solid rgba($borderColor, 0.5);
    background: rgba(theme.$theme2, 0.2);
    color: $borderColor;
    padding: 0 $like8px;
    width: $w;
    @include theme.mainFont;

    filter: drop-shadow(2px 2px 2px black);
    

    $h: $inputH;
    height: $h;
    font-size: $inputH*2/3;
    line-height: $inputH;
    box-sizing: border-box;

    margin: calc(50vh - #{$h}/2) auto;
    cursor: pointer;
    transform: scale(1);
    border-radius: $like8px/2;

  }

  .btn--max {
    position: fixed;
    top: calc(50vh - #{$inputH}/2 + #{$inputOverlayButtonHeight} - #{$inputOverlayGutter}/2);
    right: calc(50vw - #{$w}/2 + #{$inputOverlayGutter});
  }
  .btn--back {
    position: fixed;
    top: calc(50vh - #{$inputH} - #{$inputOverlayGutter}/2);
    left: calc(50vw - #{$w}/2);
  }

  .btn--approve{
    position: fixed;
    top: calc(50vh + #{$inputH}/2 + #{$inputOverlayGutter});
    right: calc(50vw - #{$w}/2);
  }

  .btn--deposit, .btn--withdraw {
    position: fixed;
    top: calc(50vh + #{$inputH}/2 +  #{$inputOverlayGutter}*2 + #{$inputOverlayButtonHeight});
    right: calc(50vw - #{$w}/2);
  }

  &__balance, &__poolbalance  {
    position: fixed;
    top: calc(50vh - #{$inputH} + #{$inputOverlayGutter}/2);
    right: calc(50vw - #{$w}/2);
    color: $borderColor;
    font-size: $inputOverlayButtonSize;

  }

  &__allowance {
    position: fixed;
    top: calc(50vh - #{$inputH} - #{$inputOverlayGutter}/2 - #{$inputOverlayButtonSize});
    right: calc(50vw - #{$w}/2);
    color: $borderColor;
    font-size: $inputOverlayButtonSize;
  }


 &.INTENT__theInput--add {
    .btn--approve, .btn--deposit {
      display: block;
    }
    .btn--withdraw {
      display: none;
    }
    .theInput__poolbalance {
      display: block;
      top: calc(50vh - #{$inputH} + #{$inputOverlayGutter}/2 + #{$inputOverlayButtonSize}*2);
    }
    .theInput__balance, .theInput__allowance {
      display: block;
    }
  }
  &.INTENT__theInput--remove {
    .btn--deposit {
      display: none;
    }
    .btn--approve, .btn--withdraw {
      display: block;
    }
    .theInput__poolbalance {
      top: calc(50vh - #{$inputH} + #{$inputOverlayGutter}/2 + #{$inputOverlayButtonSize}*2);
      display: block;
    }
    .theInput__balance, .theInput__allowance {
      display: block;
    }
  }
}


.pending {
  $penderH: 48px;
    position: fixed;
    z-index: 14;
    top:0;
    left: 0;
    height: $penderH;
    line-height: $penderH;
    width: 100%;
    background: $theme2;
    color: $bg;
    text-align: center;
    font-size: $penderH*2/3;
    @include theme.titleFont;
    font-weight: 500;
    &--false {
      display: none;
    }
  }

.info {
 
  padding: 0 16px 16px;
  color: $fontColor;

  a {
    text-decoration: none;

    font-weight: 600;
    display: inline-block;
    margin-bottom: 16px;
    color: scale-color(theme.$theme3, $lightness: 50%);
    transform: rotateZ(0deg);
    transition: 0.2s transform ease-in-out;
    filter: drop-shadow(2px 2px 2px black);
    &:hover {
      color:  theme.$theme1;
  
    }
    & + a {
      margin-left: 16px;
    }
  }

  p {
    font-weight: 400;

  }
}

strong {
  font-weight: 400;
  @include theme.titleFont;
  font-size: 1.4em;
}

.TOGGLE__stats--false {
  display: none;
}
.TOGGLE__stats--true {
  display: inline-block;
}


@include dapp.Dapp;
@include bg.bg;

h1, h2, h3, .App-name {
  filter: drop-shadow(2px 2px 2px black);
}

@media only screen and (orientation: portrait) {
  .farmland {
  width: auto;
  box-sizing: border-box;
  padding: $HeaderHeight 16px 16px;
  position: relative;
  z-index: 1;
}

@include pool.Pool($PoolWidth);
  .theInput {
  &.TOGGLE__theInput--false {
    display: none;
  }
  &.TOGGLE__theInput--true {
    display: block;
  }

  $w: 100%;

  z-index: 12;
  position: fixed;
  text-align: center;
  background: rgba(0,0,0,0.8);
  top: 0;
  left: 0;
  right: 0; 
  bottom: 0;

  $inputH: $w/6.5;
  $like8px: $w/39;
  input {
    display: block;
     outline: none;
    border: 2px solid rgba($borderColor, 0.5);
    background: rgba(theme.$theme2, 0.2);
    color: $borderColor;
    padding: 0 $like8px;
    width: $w;
    @include theme.mainFont;

    filter: drop-shadow(2px 2px 2px black);
    

    $h: 128px;
    height: $h;
    font-size: 24px;
    line-height: $inputH;
    box-sizing: border-box;

    margin: calc(50vh - #{$h}/2) auto;
    cursor: pointer;
    transform: scale(1);
    border-radius: $like8px/2;

  }

  .btn--max {
    position: fixed;
    top: calc(50vh - #{$inputH}/2 + #{$inputOverlayButtonHeight} - #{$inputOverlayGutter}/2);
    right: calc(50vw - #{$w}/2 + #{$inputOverlayGutter});
  }
  .btn--back {
    position: fixed;
    top: calc(50vh - #{$inputH} - #{$inputOverlayGutter}/2);
    left: calc(50vw - #{$w}/2);
  }

  .btn--approve, .btn--withdraw {
    position: fixed;
    top: calc(50vh + #{$inputH}/2 + #{$inputOverlayGutter});
    right: calc(50vw - #{$w}/2);
  }

  .btn--deposit {
    position: fixed;
    top: calc(50vh + #{$inputH}/2 +  #{$inputOverlayGutter}*2 + #{$inputOverlayButtonHeight});
    right: calc(50vw - #{$w}/2);
  }

  &__balance, &__poolbalance  {
    position: fixed;
    top: calc(50vh - #{$inputH} + #{$inputOverlayGutter}/2);
    right: calc(50vw - #{$w}/2);
    color: $borderColor;
    font-size: $inputOverlayButtonSize;

  }

  &__allowance {
    position: fixed;
    top: calc(50vh - #{$inputH} - #{$inputOverlayGutter}/2 - #{$inputOverlayButtonSize});
    right: calc(50vw - #{$w}/2);
    color: $borderColor;
    font-size: $inputOverlayButtonSize;
  }


 &.INTENT__theInput--add {
    .btn--approve, .btn--deposit {
      display: block;
    }
    .btn--withdraw {
      display: none;
    }
    .theInput__poolbalance {
      display: none;
    }
    .theInput__balance, .theInput__allowance {
      display: block;
    }
  }
  &.INTENT__theInput--remove {
    .btn--approve, .btn--deposit {
      display: none;
    }
    .btn--withdraw {
      display: block;
    }
    .theInput__poolbalance {
      display: block;
    }
    .theInput__balance, .theInput__allowance {
      display: none;
    }
  }
}
}

.farmland.show-closed--false .pool {
  &.pool--closed.poolsvg--false {
    display: none;
  }
}

.toggle-closed {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99999;
}