@use "src/_theme.scss";
@mixin Dapp {
.DappSection {
  position: relative;
  padding-top: 24px;
  margin-top: 64px;
  h2 {
  	@include theme.titleFont;
  	font-size: 40px;
  	margin-top: 0;
  	position: absolute;
  	top: -64px;
  }
  .svg {
    height: 48px;
    top: -48px;
    left: 0;
    right: 0;
    bottom: initial;
    svg {
    overflow: visible;
}
  }
}
}